'use strict';

const React = require('react');
const PropTypes = require('prop-types');

FormJoin.propTypes = {
    submit: PropTypes.func.isRequired,
    submitText: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
};

FormJoin.displayName = 'FormJoin';

module.exports = FormJoin;

function FormJoin(props) {
    const {
        submit,
        submitText,
        loading,
    } = props;
    
    return (
        <form
            onSubmit={ submit }
            className="block inline-block width"
            action="/join"
            method="post"
        >
            <label htmlFor="username">
                Username
            </label>
            <input
                autoCapitalize="off"
                autoCorrect="off"
                autoFocus
                id="username"
                name="username"
                tabIndex="1"
            />
            <label htmlFor="email">
                Email Address
            </label>
            <input
                autoCapitalize="off"
                autoCorrect="off"
                id="email"
                name="email"
                tabIndex="2"
                type="email"
            />
            <label htmlFor="password">
                Password
            </label>
            <input
                id="password"
                name="password"
                tabIndex="3"
                type="password"
            />
            <input
                type="submit"
                value={submitText}
                disabled={ loading }
                className="btn margin-top"
                tabIndex="4"
            />
        </form>
    );
}

