'use strict';

const React = require('react');
const PropTypes = require('prop-types');

module.exports = Login;

Login.propTypes = {
    submit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    submitText: PropTypes.string.isRequired,
};

function Login(props) {
    const {
        submit,
        loading,
        submitText,
    } = props;
    
    return (
        <form onSubmit={ submit }
            className="block inline-block width"
            action="/login"
            method="post"
        >
            <label htmlFor="username">
                Username
            </label>
            <input
                required
                autoCapitalize="off"
                autoCorrect="off"
                autoFocus
                id="username"
                name="username"
                tabIndex="1"
            />
            <label htmlFor="password">
                Password
            </label>
            <input
                required
                type="password"
                id="password"
                name="password"
                tabIndex="2"
            />
            <input
                type="submit"
                value={submitText}
                disabled={ loading }
                className="btn margin-top"
                tabIndex="3"
            />
        </form>
    );
}

