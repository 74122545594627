'use strict';

const React = require('react');
const PropTypes = require('prop-types');

module.exports = Header;

Header.displayName = 'Header';
Header.propTypes = {
    title: PropTypes.string,
};

function Header({title}) {
    return (
        <header className="center-horizontal">
            <a href="http://cloudcmd.io">
                <img className="logo center-horizontal" src="/common/img/cloudcmd.png" alt="Cloud Commander" />
            </a>
            <h3>{ title }</h3>
        </header>
    );
}

