'use strict';

const React = require('react');

const Header = require('./header');
const Footer = require('./footer');
const Error = require('./error');
const Form = require('./forms');
const PropTypes = require('prop-types');

module.exports = App;
App.propTypes = {
    message: PropTypes.string,
    title: PropTypes.string,
    form: PropTypes.string,
    link: PropTypes.object,
    text: PropTypes.string,
    tabIndex: PropTypes.string,
    setMessage: PropTypes.func,
    formType: PropTypes.string.isRequired,
};

function App(props) {
    const {
        title,
        message,
        formType,
        setMessage,
        link,
        text,
        tabIndex,
    } = props;
    
    return (
        <section className="text-align-center">
            <Header title={ title }/>
            <Error message={ message }/>
            <Form
                type={ formType }
                setMessage={ setMessage }
            />
            <Footer
                link={ link }
                text={ text }
                tabIndex={ tabIndex }
            />
        </section>
    );
}

