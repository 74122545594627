'use strict';

const React = require('react');
const AppContainer = require('../app-container');
const PropTypes = require('prop-types');

module.exports = PageJoin;

PageJoin.propTypes = {
    staticContext: PropTypes.object,
};

function PageJoin({staticContext}) {
    const link = {
        text: 'Login',
        href: '/login',
    };
    
    const {message} = staticContext || {
        message: '',
    };
    
    return (
        <AppContainer
            form='join'
            title='Join to IOCMD'
            message={ message }
            link={ link }
            text='Already have an account?'
            tabIndex='5'
        />
    );
}

