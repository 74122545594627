'use strict';

const React = require('react');
const PropTypes = require('prop-types');

const {useState} = React;

const App = require('./app');

function AppContainer(props) {
    const [message, setMessage] = useState(props.message);
    
    return (
        <App
            title={ props.title }
            message={ message }
            formType={ props.form }
            setMessage={ setMessage }
            link={ props.link }
            text={ props.text }
            tabIndex={ props.tabIndex }
        />
    );
}

module.exports = AppContainer;

AppContainer.propTypes = {
    message: PropTypes.string,
    title: PropTypes.string,
    form: PropTypes.string,
    link: PropTypes.object,
    text: PropTypes.string,
    tabIndex: PropTypes.string,
};

