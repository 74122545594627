'use strict';

module.exports = (url, form) => {
    const urlParams = getUrlParams(form);
    return post(url, urlParams);
};

function getUrlParams(form) {
    const urlParams = new URLSearchParams();
    const entries = new FormData(form).entries();
    
    for (const entry of entries) {
        urlParams.append(...entry);
    }
    
    return urlParams;
}

async function post(url, body) {
    const res = await fetch(url, {
        method: 'post',
        body,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        },
    });
    
    if (res.status !== 400)
        return;
    
    const {message} = await res.json();
    
    throw Error(message);
}

