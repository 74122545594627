'use strict';

const React = require('react');
const {useState} = React;
const tryToCatch = require('try-to-catch');
const PropTypes = require('prop-types');
const currify = require('currify');

const FormJoin = require('./join');

const getSubmitText = ({loading} = {}) => {
    if (loading)
        return 'Loading...';
    
    return 'Join';
};

const submit = currify(async ({setMessage, setLoading}, event) => {
    event.preventDefault();
    
    const send = require('../../client/send');
    const form = document.querySelector('form');
    
    setLoading(true);
    const [e] = await tryToCatch(send, '/join', form);
    
    if (e) {
        setLoading(false);
        setMessage(e.message);
        return;
    }
    
    setMessage('');
    const {value} = document.querySelector('#username');
    window.location.href = `/${value}`;
});

const FormJoinContainer = (props) => {
    const [loading, setLoading] = useState(false);
    const {setMessage} = props;
    
    return (
        <FormJoin
            submit={ submit({setMessage, setLoading}) }
            submitText={ getSubmitText({loading})}
            loading={ loading }
        />
    );
};

module.exports = FormJoinContainer;
module.exports.getSubmitText = getSubmitText;

FormJoinContainer.displayName = 'FormJoinContainer';

FormJoinContainer.propTypes = {
    setMessage: PropTypes.func.isRequired,
};

