'use strict';

const React = require('react');
const PropTypes = require('prop-types');

const getClassName = (message) => {
    const className = 'block width center-horizontal error margin-bottom';
    
    if (!message)
        return `hidden ${className}`;
    
    return className;
};

const error = ({message}) => {
    const className = getClassName(message);
    
    return (
        <section className={ className }>
            { message }
        </section>
    );
};

module.exports = error;

error.displayName = 'Error';

error.propTypes = {
    message: PropTypes.string,
};

