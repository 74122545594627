'use strict';

const React = require('react');
const ReactDOM = require('react-dom');
const {BrowserRouter} = require('react-router-dom');
const Routes = require('../routes');

ReactDOM.hydrate(
    
    <BrowserRouter>
        <Routes/>
    </BrowserRouter>
    , document.querySelector('main')
);

