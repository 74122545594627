'use strict';

const React = require('react');
const {useState} = React;
const PropTypes = require('prop-types');
const FormLogin = require('./login');

const getSubmitText = ({loading} = {}) => {
    if (loading)
        return 'Loading...';
    
    return 'Submit';
};

function FormLoginContainer(props) {
    const [loading, setLoading] = useState(false);
    
    function enableSubmit() {
        setLoading(false);
    }
    
    function disableSubmit() {
        setLoading(true);
    }
    
    function submit(event) {
        event.preventDefault();
        
        const post = require('../../client/send');
        
        const {setMessage} = props;
        
        disableSubmit();
        
        post('/login', document.querySelector('form'))
            .then(() => {
                setMessage('');
                const {value} = document.querySelector('#username');
                window.location.href = `/${value}`;
            }).catch(({message}) => {
                enableSubmit();
                setMessage(message);
            });
    }
    
    return (
        <FormLogin
            submit={ submit }
            loading={ loading }
            submitText={ getSubmitText({loading})}
        />
    );
}

FormLoginContainer.propTypes = {
    setMessage: PropTypes.func.isRequired,
};

module.exports = FormLoginContainer;
module.exports.getSubmitText = getSubmitText;

