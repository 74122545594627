'use strict';

const React = require('react');

const Login = require('./pages/login');
const Join = require('./pages/join');

const {Route, Switch} = require('react-router');

const routes = () => {
    return (
        <Switch>
            <Route exact path="/login" component={ Login }/>
            <Route exact path="/join" component={ Join }/>
        </Switch>
    );
};

module.exports = routes;

routes.displayName = 'Routes';

