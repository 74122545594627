'use strict';

const React = require('react');
const PropTypes = require('prop-types');
const FormLogin = require('./login-container');
const FormJoin = require('./join-container');

module.exports = Form;

function getForm(type) {
    if (type === 'login')
        return FormLogin;
    
    return FormJoin;
}

function Form(props) {
    const CustomForm = getForm(props.type);
    
    return (
        <CustomForm { ...props }/>
    );
}

Form.displayName = 'Form';
Form.propTypes = {
    type: PropTypes.string.isRequired,
    setMessage: PropTypes.func.isRequired,
};
