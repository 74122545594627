'use strict';

const React = require('react');
const {Link} = require('react-router-dom');
const PropTypes = require('prop-types');

const footer = (props) => {
    const {
        link,
        text,
        tabIndex,
    } = props;
    
    return (
        <footer className="block width center-horizontal margin-top">
            <p className="text-align-center no-margin">{ text + ' ' }
                <Link to={ link.href } tabIndex={ tabIndex }>{ link.text }</Link>
            </p>
        </footer>
    );
};

module.exports = footer;

footer.displayName = 'Footer';
footer.propTypes = {
    link: PropTypes.object,
    text: PropTypes.string,
    tabIndex: PropTypes.string,
};

